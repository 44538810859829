import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Blog from "../../pages/blog"
import img4 from "../../assets/images/blog/blog-img4.jpg"
import img5 from "../../assets/images/blog/blog-img5.jpg"
import img6 from "../../assets/images/blog/blog-img6.jpg"
import img7 from "../../assets/images/blog/blog-img7.jpg"
import img8 from "../../assets/images/blog/blog-img8.jpg"
import img10 from "../../assets/images/blog/blog-img10.jpg"
import user1 from "../../assets/images/user1.jpg"
import user2 from "../../assets/images/user2.jpg"
import user3 from "../../assets/images/user3.jpg"
import user4 from "../../assets/images/user4.jpg"
import user5 from "../../assets/images/user5.jpg"
import user6 from "../../assets/images/user6.jpg"
import BlogDetails from "../../pages/blog-details"
import { useIntl } from "react-intl"
//generate query for courses

const BlogCard = (blog: any) => {
  const intl = useIntl()
  return (
    <Link
      language={intl.locale}
      to="/blog-details"
      state={{ id: blog?.blog?.id }}
    >
      <div
        className="blog-area ptb-100"
        style={
          intl.locale === "ar"
            ? { textAlign: "right", direction: "rtl" }
            : { textAlign: "left", direction: "ltr" }
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                  <img
                    src={
                      "http://162.19.67.2:1337" +
                      blog?.blog?.attributes?.image?.data?.attributes?.url
                    }
                    alt="image"
                  />
                </div>

                <div className="post-content">
                  <ul className="post-meta d-flex justify-content-between align-items-center">
                    <li>
                      <div className="post-author d-flex align-items-center">
                        <img
                          src={
                            "http://162.19.67.2:1337" +
                            blog?.blog?.attributes?.author?.data?.attributes?.picture?.data?.attributes?.url
                          }
                          className="rounded-circle"
                          alt="blog"
                        />
                        <span>{blog?.blog?.attributes?.author.data.attributes.firstname + " " + blog?.blog?.attributes?.author.data.attributes.lastname}</span>
                      </div>
                    </li>
                    <li>
                      <i className="flaticon-calendar"></i> {blog?.blog?.attributes?.date}
                    </li>
                  </ul>
                  <h3>
                    <Link language={intl.locale} to="/blog-details"
                      state={{ id: blog?.blog?.id }}>{blog?.blog?.attributes?.title}</Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default BlogCard
