import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import BlogCard from "../components/BlogContent/BlogCard"
import { ApolloProvider, gql, useMutation, useQuery } from "@apollo/client"
import { useIntl } from "react-intl"
import client from "../utils/apollo-client"


const GET_BLOGS = gql`
  query GetBlog($locale: I18NLocaleCode) {
    blogs(locale: $locale) {
      data {
        id
        attributes {
          title
          slug
          caption
          date
          image {
            data {
              attributes {
                url
              }
            }
          }
          author {
            data {
              attributes {
                firstname
                lastname
                picture {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Blog = () => {
  const intl = useIntl()

  const { loading, error, data } = useQuery(GET_BLOGS, {
    client: client,
    variables: {
      locale: intl.locale,
    },
  })

  return (
    <ApolloProvider client={client}>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={intl.formatMessage({ id: "blog" })}
          homePageText={intl.formatMessage({ id: "home" })}
          homePageUrl={intl.locale === "ar" ? "/" : "/".concat(intl.locale)}
          activePageText={intl.formatMessage({ id: "blog" })}
        />
        {loading && <p>Loading ...</p>}
        {!loading &&
          data?.blogs?.data?.map((element: any) => {
            return <BlogCard blog={element} />
          })}

        <Footer />
      </Layout>
    </ApolloProvider>
  )
}

export default Blog
